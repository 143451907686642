import React from 'react';
import PropTypes from 'prop-types';
import MenuOpen from '@material-ui/icons/MenuOpen';
import styled from 'styled-components';

import { IconButton } from '@material-ui/core';

const Wrapper = styled(IconButton)`
    transition: transform
      ${({ theme }) => `${theme.transitions.easing.sharp} ${theme.transitions.duration.complex}`}ms;
      transform: rotateY(180deg);
    &.iconsOnly {
      transform: rotateY(0deg);
    }
    button {
      margin: auto;
      display: block;
    }
`;

const NavMenuLabelsToggle = ({
  className,
  onClick,
  showNavMenuLabels,
}) => {
  const classes = ['NavMenuLabelsToggle'];
  if (className) classes.push(className);

  return (
    <Wrapper
      edge="start"
      className={
        showNavMenuLabels ? 'expandCollapsBtn' : 'expandCollapsBtn iconsOnly'
      }
      color="inherit"
      aria-label="menu"
      onClick={onClick}
    >
      <MenuOpen />
    </Wrapper>
  );
};

NavMenuLabelsToggle.displayName = 'NavMenuLabelsToggle';

NavMenuLabelsToggle.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  showNavMenuLabels: PropTypes.bool,
};

NavMenuLabelsToggle.defaultProps = {
  className: null,
  showNavMenuLabels: true,
};

export default NavMenuLabelsToggle;
