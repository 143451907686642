import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from '../helpers/debounce';

// TODO:
// - adding horizontal overflow management

const Wrapper = styled.div`
  width: 100%;
  -webkit-overflow-scrolling: touch;

  &.horizontal{
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  &.vertical{
    max-height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  >*{
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
`;

const ScrollableBox = ({
  className, as, children, direction,
}) => {
  const elRef = React.useRef();

  function resizeCallback([entry]) {
    const width = Math.round(entry.contentRect.width);
    const height = Math.round(entry.contentRect.height);

    if (elRef.current) {
      const overflowing = direction === 'horizontal' ? elRef.current.scrollWidth > width : elRef.current.scrollHeight > height;
      elRef.current.classList.toggle('overflow', overflowing);
    }
  }

  React.useEffect(() => {
    const observer = new window.ResizeObserver(debounce(resizeCallback, 100));
    observer.observe(elRef.current);

    return () => {
      observer.unobserve(elRef.current);
    };
  }, []);


  const classes = ['ScrollableBox', direction];
  if (className) classes.push(className);

  return (
    <Wrapper as={as} className={classes.join(' ')} ref={elRef}>
      {children}
    </Wrapper>
  );
};

ScrollableBox.displayName = 'ScrollableBox';

ScrollableBox.propTypes = {
  className: PropTypes.string,
  as: PropTypes.string,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

ScrollableBox.defaultProps = {
  className: null,
  as: 'div',
  direction: 'horizontal',
};

export default ScrollableBox;
