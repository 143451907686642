import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default (props) => (
  <SvgIcon
    {...props}
    xmlns="http://www.w3.org/2000/Wrapper"
    viewBox="0 0 140 77"
  >
    <path d="M130.447 47.654h-8.059v8.136c0 2.686-2.21 4.897-4.896 4.897-2.765 0-4.978-2.21-4.978-4.897v-8.136h-8.055a4.87 4.87 0 01-4.898-4.896c0-2.684 2.212-4.898 4.898-4.898h8.055v-8.056c0-2.763 2.213-4.895 4.978-4.895a4.869 4.869 0 014.896 4.895v8.056h8.059c2.684 0 4.894 2.214 4.894 4.898a4.87 4.87 0 01-4.894 4.896zM17.91 17.105L4.346 61.663c-1.345 4.416 1.35 9.15 6.021 10.567l.646.2c4.668 1.422 9.544-1.008 10.889-5.423l5.172-16.983zM94.209 4.938l-.646-.198c-4.672-1.42-9.546 1.007-10.892 5.424l-5.17 16.986 9.164 32.915 13.563-44.557c1.346-4.418-1.349-9.149-6.019-10.57zM83.289 62.053L68.854 10.195c-1.185-4.257-5.644-6.76-10.148-5.82-3.385.305-6.354 2.492-7.359 5.79L44.874 31.43l-5.912-21.234c-1.235-4.449-6.051-6.994-10.755-5.686l-.65.181c-4.703 1.31-7.512 5.978-6.273 10.425l14.435 51.858c1.187 4.256 5.65 6.762 10.153 5.821 3.383-.308 6.353-2.495 7.355-5.79l6.474-21.267 5.911 21.235c1.24 4.447 6.054 6.992 10.757 5.683l.651-.182c4.7-1.303 7.508-5.974 6.269-10.42z" />
  </SvgIcon>
);
