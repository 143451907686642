import React from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@material-ui/core';

const Header = ({ className, toolbar }) => {
  const classes = ['Header'];
  if (className) classes.push(className);

  return (
    <AppBar className={classes.join(' ')} position="static">
      {toolbar}
    </AppBar>
  );
};

Header.displayName = 'Header';

Header.propTypes = {
  className: PropTypes.string,
  toolbar: PropTypes.element.isRequired,
};

Header.defaultProps = {
  className: null,
};

export default Header;
