import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const Wrapper = styled.div`
  margin: ${({ theme }) => theme.spacing()}px;
  text-align: center;
  font-style: italic;
  opacity: 0.6;
`;

const Component = ({ className }) => {
  const classes = ['copyright'];
  if (className) {
    classes.push(className);
  }

  return (
    <Wrapper className={classes.join(' ')}>
      <Typography variant="caption">
        &copy;
        {new Date().getFullYear()}
        &nbsp;-&nbsp;Mobile&nbsp;Worker&nbsp;Plus
      </Typography>
    </Wrapper>
  );
};

Component.propTypes = {
  className: PropTypes.string,
};

Component.defaultProps = {
  className: null,
};

Component.displayName = 'Copyright';

export default Component;
