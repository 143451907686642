import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import { Typography, TablePagination } from '@material-ui/core';

const StyledTableView = styled.div`
  table {
    position: relative;
    border-collapse: collapse;
  }

  table > caption {
    caption-side: top;
  }

  th {
    position: sticky;
    top: 0px;
    z-index:2;
  }

  .tableRow {
    &.clickable {
      cursor: pointer;
      &:focus {
        background-color: ${({ theme }) => theme.palette.action.focus};
      }
      &:hover {
        background-color: ${({ theme }) => theme.palette.action.hover};
      }
    }
    &.disabled {
      td {
        color: ${({ theme }) => theme.palette.text.disabled};
      }
    }

    &.selected {
      background-color:${({ theme }) => theme.palette.action.hover};
      td{
        font-weight: bold;
        position: relative;
        &:first-child{
          &:after{
            content:"";
            position: absolute;
            width: 4px;
            left:0px;
            top:0;
            height: 100%;
            background-color:${({ theme }) => theme.palette.table.selectedRowBorder};
          }
        }
      }
    }
  }

  &.default{
    tbody {
      background-color: ${({ theme }) => theme.palette.table.body};
    }
    th {
      background-color: ${({ theme }) => theme.palette.table.head};
      box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.2);
    }
  }

  &.light{
    tbody {
      background-color: ${({ theme }) => theme.palette.table.light.body};
      td{
        border-bottom-style: dashed;
      }
    }
    th {
      background-color: ${({ theme }) => theme.palette.table.light.head};
      box-shadow: 0 5px 0px 0px ${({ theme }) => theme.palette.table.light.headShadowColor};
      padding-top: ${({ theme }) => theme.spacing(1)}px;
      padding-bottom: ${({ theme }) => theme.spacing(1)}px;
    }
  }
`;

const Placeholder = styled.div`
  height: 100%;
  font-size:${({ theme }) => theme.typography.h6.fontSize};
  display: flex;
  align-items: center;
  justify-content: center;
  color:${({ theme }) => theme.palette.table.placeholderText};
`;

const TableView = ({
  className,
  tableTitle,
  tableData,
  rowKey,
  onRowClick,
  theme,
  placeholder,
  hideKey,
  noPagination,
  defaultRowsPerPage,
}) => {
  function rowClickHandler(item) {
    return () => {
      if (!item.metadata || !item.metadata.disabled) onRowClick(item);
    };
  }

  function rowKeyUpHandler(item) {
    return (e) => {
      if (e.keyCode === 13) {
        if (!item.metadata || !item.metadata.disabled) onRowClick(item);
      }
    };
  }

  function renderTableHead() {
    if (!tableData || tableData.length === 0) return null;
    const { metadata, ...rest } = tableData[0];
    return (
      <TableRow>
        {Object.keys(rest).filter((key) => !hideKey || key !== rowKey).map((key) => (
          <TableCell key={key}>
            <strong>{key}</strong>
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function renderTableRows(data) {
    return data.map((row) => {
      const classes = ['tableRow'];

      const { metadata, ...rest } = row;
      if (onRowClick && (!metadata || !metadata.disabled)) {
        classes.push('clickable');
      }
      if (metadata && metadata.disabled) classes.push('disabled');
      if (metadata && metadata.selected) classes.push('selected');

      return (
        <TableRow
          className={classes.join(' ')}
          key={row[rowKey]}
          onClick={onRowClick && rowClickHandler(row, rowKey)}
          onKeyUp={onRowClick && rowKeyUpHandler(row, rowKey)}
          role="button"
          tabIndex={0}
        >
          {Object.entries(rest).map(([key, value]) => (
            !(hideKey && key === rowKey) && <TableCell key={key}>{value}</TableCell>
          ))}
        </TableRow>
      );
    });
  }

  const classes = ['tableView', theme];
  if (className) classes.push(className);

  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [page, setPage] = React.useState(0);
  const [paginatedData, setPaginatedData] = React.useState(tableData.slice(0, rowsPerPage));

  React.useEffect(() => {
    setPaginatedData(tableData.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
  }, [tableData, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledTableView className={classes.join(' ')}>

      {!tableData || tableData.length === 0 ? (
        <Placeholder>
          {placeholder}
        </Placeholder>
      ) : (

        <Table>
          {tableTitle && (
          <caption>
            <Typography variant="h6">{tableTitle}</Typography>
          </caption>
          )}
          <TableHead>{renderTableHead()}</TableHead>
          <TableBody>
            {renderTableRows(noPagination ? tableData : paginatedData)}
          </TableBody>
          {
            !noPagination && (
            <TableFooter>
              <TableRow>
                <TableCell colspan="100%" style={{ 'text-align': 'center', 'border-bottom': '0px' }}>
                  <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <TablePagination
                      component="div"
                      count={tableData.length}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 20, 30]}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
            )
          }
        </Table>
      )}

    </StyledTableView>
  );
};

TableView.propTypes = {
  className: PropTypes.string,
  tableTitle: PropTypes.string,
  tableData: PropTypes.arrayOf(PropTypes.any),
  rowKey: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onRowClick: PropTypes.func,
  theme: PropTypes.oneOf(['default', 'light']),
  hideKey: PropTypes.bool,
  noPagination: PropTypes.bool,
  defaultRowsPerPage: PropTypes.oneOf([5, 10, 20, 30]),
};

TableView.defaultProps = {
  className: null,
  tableTitle: null,
  onRowClick: null,
  theme: 'default',
  hideKey: false,
  tableData: null,
  placeholder: 'No data available',
  noPagination: false,
  defaultRowsPerPage: 10,
};

export default TableView;
