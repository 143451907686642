import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

const StyledWidgetView = styled(Paper)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.header`
  padding: ${({ theme }) => theme.spacing(0, 1)}px;
  margin: 0;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const WidgetTitle = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Footer = styled.div`
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  bottom: 0px;
`;
const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100px;
`;
const WidgetView = ({
  className, title, children, footer, placeholder,
}) => (
  <StyledWidgetView className={className} elevation={3}>
    {!!title && (
      <Title>
        {typeof title === 'string' ? (
          <WidgetTitle variant="h6">{title}</WidgetTitle>
        ) : (
          title
        )}
      </Title>
    )}
    {!!children && <Content className="widgetViewContent">{children}</Content>}
    {!children && <Placeholder>{placeholder}</Placeholder>}
    {!!footer && <Footer>{footer}</Footer>}
  </StyledWidgetView>
);

WidgetView.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footer: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.element,
    PropTypes.elementType,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  placeholder: PropTypes.string,
};

WidgetView.defaultProps = {
  className: '',
  title: null,
  footer: null,
  placeholder: null,
  children: null,
};

export default WidgetView;
