import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

const ViewPaper = styled(Paper)`
padding: ${({ theme }) => theme.spacing(1)}px;
${({ theme }) => theme.breakpoints.up('sm')} {
  padding: ${({ theme }) => theme.spacing(2)}px;
}
`;

ViewPaper.displayName = 'ViewPaper';

export default ViewPaper;
