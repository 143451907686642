import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map as LMap, TileLayer, Marker } from 'react-leaflet';
import Fab from '@material-ui/core/Fab';
import AdjustIcon from '@material-ui/icons/Adjust';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import debounce from '../helpers/debounce';
import LabelledMarker, { LabelledMarkerStyle } from './LabelledMarker';

// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;

const iconRetinaUrl = require('leaflet/dist/images/marker-icon-2x.png');
const iconUrl = require('leaflet/dist/images/marker-icon.png');
const shadowUrl = require('leaflet/dist/images/marker-shadow.png');

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

const StyledMap = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
  }

  > button {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 1000;
  }
`;

let buttonClicked = false;
let timeoutHnd = null;
const centerButtonClickTimeout = 100;

const Map = ({
  center,
  zoom,
  locations = [],
  markers = [],
  mapId,
  onMarkerClick,
  preventFitBounds,
}) => {
  const mapRef = useRef(null);
  const mapWrapperRef = useRef(null);
  const [mapIsCentred, setMapIsCentred] = React.useState(false);

  const centerMarkers = React.useCallback(() => {
    if (mapRef.current) {
      if (markers.length || locations.length) {
        const bounds = new L.LatLngBounds([
          ...locations.map((loc) => [loc.lat, loc.lng]),
          ...markers.map((marker) => marker.props.position),
        ]);

        if (preventFitBounds) {
          mapRef.current.leafletElement.panTo(bounds.getCenter());
        } else {
          mapRef.current.leafletElement.fitBounds(bounds);
        }
      }
      setMapIsCentred(true);
    }
  }, [markers.length, locations.length, mapRef.current]);

  function mapWillResize() {
    buttonClicked = true;
    clearTimeout(timeoutHnd);

    timeoutHnd = setTimeout(() => {
      // this is to skip the zoomstart and movestart events generated by fitBounds
      buttonClicked = false;
    }, centerButtonClickTimeout);
  }
  function centerBtnClick() {
    mapWillResize();
    centerMarkers();
  }

  function resizeCallback() {
    setMapIsCentred(false);
    if (mapRef.current && mapRef.current.leafletElement) {
      mapRef.current.leafletElement.invalidateSize(true);
    }
  }

  function handleMarkerClick(data) {
    return (e) => {
      if (onMarkerClick) onMarkerClick(data, e);
    };
  }

  React.useEffect(() => {
    // centering the map on component did mount
    centerMarkers();
  }, [centerMarkers]);

  React.useEffect(() => {
    const observer = new window.ResizeObserver(debounce(resizeCallback, 100));
    observer.observe(mapWrapperRef.current);

    // needed to allow centerMarkers to execute before adding the event listener
    const timeoutHandler = setTimeout(() => {
      // listening for some events that would enable the markers-centering button
      if (mapRef.current) mapRef.current.leafletElement.on('zoomstart movestart', () => setMapIsCentred(buttonClicked));
    }, centerButtonClickTimeout);

    return () => {
      observer.unobserve(mapWrapperRef.current);
      clearTimeout(timeoutHandler);
    };
  }, []);

  return (
    <StyledMap ref={mapWrapperRef}>
      <LabelledMarkerStyle />
      <LMap
        id={mapId}
        ref={mapRef}
        center={[center.lat, center.lng]}
        zoom={zoom}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((loc) => {
          const key = loc.key || `${loc.lat}_${loc.lng}`;

          if (loc.label !== undefined) {
            return (
              <LabelledMarker
                onClick={handleMarkerClick(loc)}
                selected={loc.metadata && loc.metadata.selected}
                key={key}
                position={loc}
                label={loc.label}
                sublabel={loc.sublabel}
                colour={loc.colour}
                selectedColour={loc.selectedColour}
              />
            );
          }
          return (
            <Marker
              onClick={handleMarkerClick(loc)}
              selected={loc.metadata && loc.metadata.selected}
              key={key}
              position={[loc.lat, loc.lng]}
            />
          );
        })}
        {markers}
      </LMap>
      <Fab color="primary" onClick={centerBtnClick} disabled={mapIsCentred}>
        <AdjustIcon />
      </Fab>
    </StyledMap>
  );
};

Map.defaultProps = {
  locations: [],
  markers: [],
  mapId: 'map',
  onMarkerClick: null,
  preventFitBounds: false,
};

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
  mapId: PropTypes.string,
  zoom: PropTypes.number.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object),
  markers: PropTypes.arrayOf(PropTypes.element),
  onMarkerClick: PropTypes.func,
  preventFitBounds: PropTypes.bool,
};

export default Map;
