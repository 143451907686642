import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export const ListItemCustomIconWrapper = styled.div`
  min-width: 54px;
  img {
    height: 24px;
    width: 24px;
  }
`;

const ListItemButton = React.forwardRef(({
  primary,
  icon,
  iconUrl,
  to,
  onExternalLinkClick,
}, ref) => (
  <li className="ListItemButton" ref={ref}>
    <ListItem button onClick={() => onExternalLinkClick(to)}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}

      {iconUrl && (
        <ListItemCustomIconWrapper>
          <img src={iconUrl} alt="" />
        </ListItemCustomIconWrapper>
      )}
      <ListItemText primary={primary} />
    </ListItem>
  </li>
));

ListItemButton.displayName = 'ListItemButton';

ListItemButton.propTypes = {
  onExternalLinkClick: PropTypes.func.isRequired,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  to: PropTypes.string,
  iconUrl: PropTypes.string,
  icon: PropTypes.element,
};

ListItemButton.defaultProps = {
  iconUrl: null,
  icon: null,
  to: null,
};

export default ListItemButton;
