import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle, useTheme } from 'styled-components';
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';

const shadowUrl = require('leaflet/dist/images/marker-shadow.png');

export const LabelledMarkerStyle = createGlobalStyle`
.wmc-labelled-marker {
  position: relative;

  .wmc-labelled-marker__shadow {
    width: 41px;
    height: 41px;
    background-image: url(${shadowUrl});
    position: absolute;
    pointer-events: none;
    top: -28px;
    left: -13px;
  }
  .wmc-labelled-marker__image {

    position: absolute;
    bottom: 10px;
    transform: translate3d(-50%, -10px,0);

    .wmc-labelled-marker__label {
      background-color: white;
      border: 3px solid ${({theme})=> theme.palette.map.marker};
      padding: 0px 7px;
      position: relative;
      z-index: 2;
      border-radius: 12px;
      min-width: 27px;
      text-align: center;
      white-space: nowrap;
      
      .wmc-labelled-marker__heading {
        font-size: 14px;
        font-weight: bold;
      }
      
      .wmc-labelled-marker__subheading {
        font-size: 10px;
        margin-top: -3px;
      }
    }
    
    &:before{
      content: "";
      border: 12px solid transparent;
      border-top: 26px solid ${({theme})=> theme.palette.map.marker};
      bottom: -10px;
      height: 0px;
      left: 50%;
      pointer-events: none;
      position: absolute;
      transform: translate3d(-50%,20px,0);
      width: 0px;
    }
  }

  &.selected{
    .wmc-labelled-marker__image:before{
        border-top-color:${({theme})=> theme.palette.map.selectedMarker}
      }
      .wmc-labelled-marker__label {
        border-color:${({theme})=> theme.palette.map.selectedMarker}
      }
    }
}
`;

const LabelledMarker = ({
  label, sublabel, selected, colour, selectedColour, ...rest
}) => {
  const className = selected ? 'selected' : '';

  const theme = useTheme();

  const colourSelected = selectedColour || theme.palette.map.selectedMarker;
  const colourUnselected = colour || theme.palette.map.marker;

  const LabelledMarkerCustomStyle = styled.div`
    .wmc-labelled-marker__image {
      
      .wmc-labelled-marker__label {
        border: 3px solid ${colourUnselected};
      }

      &:before{
        border-top-color: ${colourUnselected};
      }
    }
  
    .selected &{
        .wmc-labelled-marker__image:before{
          border-top-color:${colourSelected};
        }

        .wmc-labelled-marker__label {
          border-color:${colourSelected};
        }
      }
  `;

  const elementHtml = ReactDOMServer.renderToString(
    <LabelledMarkerCustomStyle>
      <div className="wmc-labelled-marker__shadow" />
      <div className="wmc-labelled-marker__image">
        <div className="wmc-labelled-marker__label">
          <div className="wmc-labelled-marker__heading">{label}</div>
          {sublabel && (<div className="wmc-labelled-marker__subheading">{sublabel}</div>)}
        </div>
      </div>
    </LabelledMarkerCustomStyle>,
  );

  const divIcon = L.divIcon({
    className: `wmc-labelled-marker ${className}`,
    iconAnchor: L.point(0, 12),
    opacity: 0.5,
    html: elementHtml,
  });

  return (
    <LabelledMarkerCustomStyle>
      <Marker {...rest} zIndexOffset={selected ? 1000 : 0} icon={divIcon} />
    </LabelledMarkerCustomStyle>
  );
};

LabelledMarker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selected: PropTypes.bool,
  colour: PropTypes.string,
  selectedColour: PropTypes.string,
};

LabelledMarker.defaultProps = {
  selected: false,
  colour: null,
  selectedColour: null,
};

export default LabelledMarker;
