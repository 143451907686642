import React from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

const AsideToggle = ({ className, onClick, showAside }) => {
  const classes = ['AsideToggle'];
  if (className) classes.push(className);

  return (
    <IconButton
      edge="start"
      className="sideMenu"
      color="inherit"
      aria-label="menu"
      onClick={onClick}
    >
      {showAside ? <CloseIcon /> : <MenuIcon />}
    </IconButton>
  );
};

AsideToggle.displayName = 'AsideToggle';

AsideToggle.propTypes = {
  className: PropTypes.string,
  showAside: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

AsideToggle.defaultProps = {
  className: null,
};

export default AsideToggle;
