import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmationDialog = ({
  open,
  title,
  content,
  dismissButtonLabel,
  confirmButtonLabel,
  handleDismiss,
  handleConfirm,
}) => (
  <Dialog
    open={open}
    onClose={handleDismiss}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent dividers>
      {typeof content === 'string' ? (
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      ) : (
        content
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDismiss} color="primary">
        {dismissButtonLabel}
      </Button>
      <Button onClick={handleConfirm} color="secondary" autoFocus>
        {confirmButtonLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dismissButtonLabel: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
  content: null,
  title: '',
};

export default ConfirmationDialog;
