import styled from 'styled-components';

const ViewWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1)}px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: ${({ theme }) => theme.spacing(2)}px;
  }
`;

ViewWrapper.displayName = 'ViewWrapper';

export default ViewWrapper;
