import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;

  ${({ direction }) => (direction === 'row'
    ? `
    display: flex;
  `
    : `
    position: relative;
    display:block;
    height: 100%;
    `)}

  &.animate{
    >.splitViewItem{
      transition: all ${({ theme }) => theme.transitions.duration.standard}ms
      ${({ theme }) => theme.transitions.easing.sharp};
    }
  }
`;

const ViewItem = styled.div`
  overflow: auto;

  ${({ direction, theme, p }) => (direction === 'row' ? `
    padding: ${theme.spacing(p)}px ${theme.spacing(p) / 2}px;
    &:first-child {
      padding-left:${theme.spacing(p)}px;
    }
    &:last-child {
      padding-right:${theme.spacing(p)}px;
    }
  `
    : `
    padding: ${theme.spacing(p) / 2}px ${theme.spacing(p)}px;
    &:first-child {
      padding-top:${theme.spacing(p)}px;
    }
    &:last-child {
      padding-bottom:${theme.spacing(p)}px;
    }
    `
  )}

`;

const SplitView = ({
  className,
  direction,
  ratios,
  children,
  component,
  p,
  animate,
  viewsRefs,
  ...rest
}) => {
  const classes = ['SplitView'];

  if (className) classes.push(className);
  if (animate) classes.push('animate');

  // alternative ref forwarding
  children.forEach(() => { viewsRefs.push(React.useRef()); });

  return (
    <Wrapper
      as={component}
      direction={direction}
      className={classes.join(' ')}
      {...rest}
    >
      {children && children.map((c, index) => {
        const dimension = direction === 'row' ? 'width' : 'height';
        const ratio = ratios.split(',')[index];
        const ratioNum = parseFloat(ratio, 10);
        const itemClasses = ['splitViewItem'];

        return (
          <ViewItem
            ref={viewsRefs[index]}
            className={itemClasses.join(' ')}
          // eslint-disable-next-line react/no-array-index-key
            key={`${index}_cell`}
            direction={direction}
            p={ratioNum > 0 ? p : 0}
            style={{ [dimension]: ratio }}
          >
            {c}
          </ViewItem>
        );
      })}
    </Wrapper>
  );
};

SplitView.displayName = 'SplitView';

SplitView.propTypes = {
  component: PropTypes.elementType,
  p: PropTypes.number,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['column', 'row']),
  ratios: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  animate: PropTypes.bool,
  viewsRefs: PropTypes.arrayOf(PropTypes.shape({ current: PropTypes.instanceOf(Element) })),
};

SplitView.defaultProps = {
  children: null,
  component: 'div',
  className: null,
  direction: 'row',
  p: 0,
  animate: true,
  viewsRefs: [],
};
export default SplitView;
