import React from 'react';
import PropTypes from 'prop-types';

import ListItemLink from './ListItemLink';
import ListItemButton from './ListItemButton';

const MenuItem = ({ item, onClick, onExternalLinkClick }) => {
  if (item && item.external) {
    return (
      <ListItemButton
        to={item.href}
        primary={item.label}
        icon={item.icon}
        key={item.label}
        iconUrl={item.iconUrl}
        onExternalLinkClick={onExternalLinkClick}
      />
    );
  }

  return (
    <ListItemLink
      to={item.href}
      onClick={onClick}
      primary={item.label}
      icon={item.icon}
      key={item.label}
    />
  );
};

const SubMenuItemType = {
  label: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.element,
};

export const MenuItemType = {
  ...SubMenuItemType,
  submenus: PropTypes.arrayOf(PropTypes.shape(SubMenuItemType)),
};

MenuItem.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.element,
    iconUrl: PropTypes.string,
    external: PropTypes.bool,
    submenus: PropTypes.shape(SubMenuItemType),
  }).isRequired,
  onClick: PropTypes.func,
  onExternalLinkClick: PropTypes.func,
};

MenuItem.defaultProps = {
  onClick: null,
  onExternalLinkClick: null,
};

export default MenuItem;
