import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Toolbar as MuiToolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core';
import Select from '@material-ui/core/Select';
import PersonIcon from '@material-ui/icons/Person';
import FormControl from '@material-ui/core/FormControl';
import Logo from './Logo';

const Wrapper = styled(MuiToolbar)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding-left: ${({ theme }) => theme.spacing(2)}px;

  .groupSelect {
    color: inherit;
    max-width: 160px;
    ${({ theme }) => theme.breakpoints.up('sm')} {
      max-width: 200px;
    }
    ${({ theme }) => theme.breakpoints.up('md')} {
      max-width: 240px;
    }
  }

  ${({ theme }) => `
  .mwLogo {
      margin: 0 ${theme.spacing(1)}px;
      font-size: 34px;
      color: ${theme.palette.primary.contrastText};
      ${theme.breakpoints.up('sm')} {
        font-size: 48px;
      }
      ${theme.breakpoints.up('md')} {
        font-size: 60px;
      }
    }
  `}
  .buttonsArea {
    margin-left: auto;
    flex-shrink: 0;
  }

`;

const Toolbar = ({
  onLogoutClick,
  onChangePasswordClick,
  onGroupChange,
  asideToggle,
  navMenuLabelsToggle,
  groups,
  activeGroupId,
  buttons,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function onShowUserProfileMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function onHideUserProfileMenu() {
    setAnchorEl(null);
  }

  function handlePasswordChangeClick() {
    setAnchorEl(null);
    onChangePasswordClick();
  }

  const renderGroupsDropDown = () => {
    if (!groups || groups.length === 0) {
      return null;
    }

    return (
      <FormControl
        variant="outlined"
        className="groupDropdownWrapper"
      >
        <Select
          onChange={(e) => onGroupChange(e.target.value)}
          defaultValue={activeGroupId || -1}
          className="groupSelect"
        >
          <MenuItem
            value={-1}
            disabled
          >
            <em>... select group</em>
          </MenuItem>
          {groups.map((group) => (
            <MenuItem
              key={group.groupId}
              value={group.groupId}
            >
              {group.description || group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Wrapper>
      {asideToggle}
      {navMenuLabelsToggle}
      <Link to="/">
        <Logo className="mwLogo" />
      </Link>
      {renderGroupsDropDown()}

      <Box className="buttonsArea">
        {buttons}
        <IconButton
          color="inherit"
          edge="end"
          onClick={onShowUserProfileMenu}
        >
          <PersonIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onHideUserProfileMenu}
        >
          <MenuItem onClick={handlePasswordChangeClick}>Change password</MenuItem>
          <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
        </Menu>
      </Box>
    </Wrapper>
  );
};

export default Toolbar;

Toolbar.propTypes = {
  onChangePasswordClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onGroupChange: PropTypes.func,
  asideToggle: PropTypes.element,
  navMenuLabelsToggle: PropTypes.element,
  buttons: PropTypes.arrayOf(PropTypes.element),
  groups: PropTypes.arrayOf(PropTypes.shape({})),
  activeGroupId: PropTypes.number,
};

Toolbar.defaultProps = {
  onGroupChange: null,
  asideToggle: null,
  navMenuLabelsToggle: null,
  buttons: [],
  groups: null,
  activeGroupId: null,
};
