import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Menu, useMediaQuery, useTheme, ListItemIcon, ListItem, ListItemText,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemButton from './ListItemButton';
import MenuItem from './MenuItem';

const StyledLabel = styled.div`
  width: 100%;
  display: flex;
  align-content: center;

  > *:first-child{
    flex-grow: 1;
  }

`;

const StyledSubMenu = styled.li`
  &.smallVp {

    .MuiListItemText-root {
      margin: 0;
      padding: 0;
    }
  }
  .dropdownIcon {
    transition: transform .3s ease;
    transform: rotateZ(${({ open }) => (open ? '180deg' : '0deg')});
  }
`;

const SubMenu = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef();

  const theme = useTheme();
  const mqSm = useMediaQuery(theme.breakpoints.only('xs'));

  const renderForSmallVP = () => (
    <StyledSubMenu className="smallVp" open={open}>
      <ListItem
        button
        onClick={() => setOpen(!open)}
      >
        {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
        <ListItemText
          primary={(
            <StyledLabel className="StyledLabel">
              <span>{item.label}</span>
              <ExpandMoreIcon className="dropdownIcon" />
              <span
                ref={anchorRef}
                className="emptySpan"
              />
            </StyledLabel>
          )}
        />
      </ListItem>
      {open && (
      <ul>
        {item.submenus.map((submenuItem) => (
          <MenuItem
            item={submenuItem}
            key={item.href}
          />
        ))}
      </ul>
      )}
    </StyledSubMenu>
  );

  const renderLargeVP = () => (
    <div>
      <ListItemButton
        ref={anchorRef}
        icon={item.icon}
        primary={(
          <StyledLabel>
            <span>{item.label}</span>
            <ChevronRightIcon />
          </StyledLabel>
        )}
        item={item}
        onExternalLinkClick={() => {
          setAnchorEl(anchorRef.current);
          setOpen(true);
        }}

      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => setOpen(false)}
      >
        {
          item.submenus.map((elem) => (
            <MenuItem
              item={elem}
              key={item.href}
              onClick={() => setOpen(false)}
            />
          ))
        }

      </Menu>
    </div>
  );

  return mqSm ? renderForSmallVP() : renderLargeVP();
};

SubMenu.propTypes = {
  item: PropTypes.shape({}).isRequired,
};

export default SubMenu;
