import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;

  .carouselTile {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: white;
    bottom: 0;
  }
`;

const Carousel = ({ className, children, selectedTileIdx }) => {
  const classes = ['Carousel'];
  const currentTileRef = React.useRef();
  const carouselRef = React.useRef();
  const [currentIdx, setCurrentIdx] = React.useState(selectedTileIdx);

  const transitions = useTransition(currentIdx, null, {
    from: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-40px,0)' },
  });

  if (className) classes.push(className);

  React.useEffect(() => {
    setCurrentIdx(selectedTileIdx);
  }, [selectedTileIdx]);

  return (
    <Wrapper className={classes.join(' ')} ref={carouselRef}>
      {children.map((tile, index) => {
        const tileClasses = ['carouselTile'];

        return transitions.map(({ item, props, key }) => (
          <animated.div
            style={props}
            key={key}
          >
            {item === index && (
              <div
                key={key}
                className={tileClasses.join(' ')}
                ref={index === currentIdx ? currentTileRef : null}
              >
                {tile}
              </div>
            )}
          </animated.div>
        ));
      })}
    </Wrapper>
  );
};

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  selectedTileIdx: PropTypes.number,
};

Carousel.defaultProps = {
  className: null,
  selectedTileIdx: 0,
};

export default Carousel;
