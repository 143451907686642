/* eslint-disable spaced-comment */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';

import ListSubheader from '@material-ui/core/ListSubheader';
import { Paper } from '@material-ui/core';

import MenuItem, { MenuItemType } from './MenuItem';
import { ListItemCustomIconWrapper } from './ListItemButton';
import SubMenu from './SubMenu';

const Wrapper = styled(Paper)`
  min-height: 100%;
  transition: width ${({ theme }) => theme.transitions.duration.short}ms
    ${({ theme }) => theme.transitions.easing.easeOut};
  white-space: nowrap;

  .MuiListItemText-root {
    padding-right: ${({ theme }) => theme.spacing(4)}px;
  }

  &.compact {
    min-width: auto;
    .MuiListItemIcon-root {
      min-width: 0;
    }

    .MuiListSubheader-root,
    .MuiListItemText-root {
      display: none;
    }

    ${ListItemCustomIconWrapper} {
      min-width: 0;
    }
  }
`;

const NavigationMenu = ({
  menuItems, onExternalLinkClick, compact, ...rest
}) => {
  const wrapperRef = React.useRef();
  const classes = ['navigationMenu'];
  //const [openMenu, setOpenMenu] = React.useState(0);

  function resetWrapperStyle() {
    wrapperRef.current.style = '';
  }
  React.useEffect(() => {
    wrapperRef.current.addEventListener('transitionend', resetWrapperStyle);

    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('transitionend', resetWrapperStyle);
      }
    };
  }, []);

  React.useEffect(() => {
    const el = wrapperRef.current;
    resetWrapperStyle();

    el.classList.toggle('compact', !compact);
    const first = el.getBoundingClientRect();

    el.classList.toggle('compact', compact);
    const last = el.getBoundingClientRect();

    el.style = `width: ${first.width}px;`;
    requestAnimationFrame(() => {
      el.style = `width: ${last.width}px;`;
    });
  }, [compact]);

  return (
    <Wrapper
      className={classes.join(' ')}
      variant="outlined"
      square
      {...rest}
      ref={wrapperRef}
    >
      {menuItems
        .map((g) => (
          <List key={g.groupLabel}>
            <ListSubheader disableSticky>{g.groupLabel}</ListSubheader>
            {g.items.map((item) => (item.submenus
              ? (
                <SubMenu
                  key={item.label}
                  item={item}
                />

              )
              : (
                <MenuItem
                  key={item.label + item.href}
                  item={item}
                  onExternalLinkClick={onExternalLinkClick}
                />
              )))}

          </List>
        ))
        .flatMap((e, index) => [<Divider key={index} />, e]) // https://stackoverflow.com/questions/37128624/terse-way-to-intersperse-element-between-all-elements-in-javascript-array/37132283#37132283
        .slice(1)}
    </Wrapper>
  );
};

NavigationMenu.defaultProps = {
  compact: false,
  onExternalLinkClick: null,
};

NavigationMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape(MenuItemType),
  ).isRequired,
  compact: PropTypes.bool,
  onExternalLinkClick: PropTypes.func,
};

export default NavigationMenu;
